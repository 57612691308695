<template>
  <div class="quick-access">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active>Quick Access</CBreadcrumbItem>
    </teleport>

    <div class="quick-access__header">
      <div class="quick-access__left-side">
        <h1 class="quick-access__heading">Quick Access</h1>
      </div>

      <div class="quick-access__right-side">
        <CAction ignore-active-project-id />
      </div>
    </div>

    <CCard>
      <CCardBody>
        <CContainer class="py-3" style="max-width: 100%; min-height: 75vh">
          <CAccordion :active-item-key="1">
            <AccordionItem :key="1">
              <CRow style="padding-right: 0">
                <CCol xs="12" style="padding-right: 0">
                  <SmartTable
                    :items="items"
                    :items-per-page="parseInt(perPage)"
                    :columns="columns"
                    :sorter-value="sort"
                    :loading="loading"
                    :active-page="activePage"
                    :per-page="perPage"
                    :pages="pages"
                    @sorter-change="onSort"
                    @update-active-page="(value) => (activePage = value)"
                    @update-per-page="(value) => (perPage = value)"
                  >
                  </SmartTable>
                </CCol>
              </CRow>
            </AccordionItem>
          </CAccordion>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>

<style lang="scss" scoped>
.quick-access {
  padding-bottom: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }
}
</style>
