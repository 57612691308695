import AccordionItem from '@/components/Page/AccordionItem'
import table from '@/mixin/table'
import CAction from '@/components/CAction'

export default {
  name: 'QuickAccess',
  components: {
    AccordionItem,
    CAction,
  },
  mixins: [table],
  inject: ['toast'],
  data() {
    return {
      mountedComponent: false,
      items: [],
      columns: [
        {
          key: 'name',
          label: 'Name',
          sorter: false,
          click: (item) => this.routePage(item),
        },
        { key: 'type', label: 'Type' },
        {
          key: 'updated_at',
          label: 'Date Of Action',
          format: (value) => (value ? this.$BtoF({ value }) : null),
        },
      ],
      loading: false,
      key: 1,
    }
  },
  mounted() {
    this.mountedComponent = true
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$http.topNav
        .quickAccess({
          params: {
            per_page: this.perPage,
            page: this.activePage,
            sort_field: this.sort && this.sort.column,
            sort_direction: this.sort && this.sort.state,
          },
        })
        .then((response) => {
          this.key++
          this.items = response.data.data
          this.pages = response.data.meta.last_page
        })
        .finally(() => (this.loading = false))
    },
    routePage(item) {
      let route = { name: 'ProjectsList' }
      switch (item.type) {
        case 'Tasks':
          route = {
            name: 'TaskContracts',
            params: {
              id: item.payload.project_id,
              task_id: item.payload.task_id,
            },
          }
          break
        case 'Projects':
          route = { name: 'Project', params: { id: item.payload.project_id } }
          break
        case 'Companies':
          route = { name: 'Company', params: { id: item.payload.company_id } }
          break
        case 'Users':
          route = {
            name: 'CompanyUser',
            params: {
              id: item.payload.company_id,
              user_id: item.payload.user_id,
            },
          }
          break
      }

      return this.$router.push(route)
    },
  },
}
